"use client";

import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "@/utils/tw";
import * as SheetPrimitive from "@radix-ui/react-dialog";
import { cva } from "class-variance-authority";
import { Icon } from "./Icon";
import { Text } from "./Text";
const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;
const SheetPortal = SheetPrimitive.Portal;
const SheetOverlay = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Overlay className={cn("fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0", className)} {...props} ref={ref} />);
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;
const sheetVariants = cva("fixed z-50 flex flex-col gap-4 bg-white p-6 shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out", {
  variants: {
    side: {
      top: "inset-x-0 top-0 border-b data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
      bottom: "inset-x-0 bottom-0 border-t data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
      left: "inset-y-0 left-0 h-full w-3/4 border-r data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left sm:max-w-sm",
      right: "inset-y-0 right-0 h-full w-3/4 border-l data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:max-w-sm"
    }
  },
  defaultVariants: {
    side: "right"
  }
});
interface SheetContentProps extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>, VariantProps<typeof sheetVariants> {
  container?: HTMLElement;
  hideClose?: boolean;
}
const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(({
  side = "right",
  className,
  children,
  container,
  hideClose,
  ...props
}, ref) => <SheetPortal container={container}>
    <SheetOverlay />
    <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({
    side
  }), className)} {...props}>
      {children}
      {!hideClose && <SheetPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary">
          <Icon icon="close" />
          <span className="sr-only">Close</span>
        </SheetPrimitive.Close>}
    </SheetPrimitive.Content>
  </SheetPortal>);
SheetContent.displayName = SheetPrimitive.Content.displayName;
const SheetHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col space-y-2 text-center sm:text-left", className)} {...props} data-sentry-component="SheetHeader" data-sentry-source-file="sheet.tsx" />;
SheetHeader.displayName = "SheetHeader";
const SheetFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} data-sentry-component="SheetFooter" data-sentry-source-file="sheet.tsx" />;
SheetFooter.displayName = "SheetFooter";
const SheetTitle = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Title>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Title asChild>
    <Text.H5 ref={ref} className={className} {...props} />
  </SheetPrimitive.Title>);
SheetTitle.displayName = SheetPrimitive.Title.displayName;
const SheetDescription = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Description>, React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>>(({
  className,
  ...props
}, ref) => <SheetPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />);
SheetDescription.displayName = SheetPrimitive.Description.displayName;
export { Sheet, SheetPortal, SheetOverlay, SheetTrigger, SheetClose, SheetContent, SheetHeader, SheetFooter, SheetTitle, SheetDescription };
export const SimpleSheet = ({
  open,
  setOpen,
  position = "left",
  container,
  title,
  description,
  className,
  children,
  content,
  hideClose
}: {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  title?: string;
  description?: string;
  children?: React.ReactNode;
  content: React.ReactNode;
  position?: "left" | "right" | "bottom" | "top";
  className?: string;
  container?: HTMLElement;
  hideClose?: boolean;
}) => {
  return <Sheet open={open} onOpenChange={setOpen} data-sentry-element="Sheet" data-sentry-component="SimpleSheet" data-sentry-source-file="sheet.tsx">
      {children && <SheetTrigger asChild>{children}</SheetTrigger>}
      <SheetContent side={position} container={container} className={className} hideClose={hideClose} data-sentry-element="SheetContent" data-sentry-source-file="sheet.tsx">
        <SheetHeader className={cn({
        hidden: !title
      })} data-sentry-element="SheetHeader" data-sentry-source-file="sheet.tsx">
          <SheetTitle data-sentry-element="SheetTitle" data-sentry-source-file="sheet.tsx">{title || ""}</SheetTitle>
          <SheetDescription className="hidden" data-sentry-element="SheetDescription" data-sentry-source-file="sheet.tsx">{description || ""}</SheetDescription>
        </SheetHeader>
        <div className="max-h-full w-full flex-1">{content}</div>
      </SheetContent>
    </Sheet>;
};